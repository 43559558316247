import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import 'moment/locale/ru';
import { extendMoment } from 'moment-range';
import Nav from '../Nav/Nav';
import CalendarDay from '../CalendarDay/CalendarDay';
import Settings from '../../utils/Settings';
import './Calendar.scss';
import CalendarModal from '../CalendarModal/CalendarModal';
import Loader from '../Loader/Loader';
import Warning from '../Warning/Warning';
import Functions from '../../utils/Functions';
import { requestCrmData } from '../../utils/Api';

Moment.locale('ru');
const moment = extendMoment(Moment);

const Calendar = ({ nowMoment }) => {
  const [Loading, setLoading] = useState(false); // 1
  const [Now, setNow] = useState(nowMoment.toObject()); // 2
  const [Category, setCategory] = useState('all-event'); // 3
  const [Grid, setGrid] = useState([]); // 4
  const [AllCrmEvents, setAllCrmEvents] = useState([]); // 5
  const [CrmSubjects, setCrmSubjects] = useState([]); // 6
  const [CrmGroups, setCrmGroups] = useState([]); // 7
  const [CrmRooms, setCrmRooms] = useState([]); // 8
  // const [WpCategory, setWpCategory] = useState([]); // 9 Not used
  const [CrmEvents, setCrmEvents] = useState(null); // 9
  const [CrmGhosts, setCrmGhosts] = useState(null); // 10
  const [ModalContent, setModalContent] = useState(null); // 11
  const [ErrorMessage, setErrorMessage] = useState(''); // 12

  // Grid
  useEffect(() => {
    let days = [];
    const currentMoment = moment(Now);
    const prevMoment = currentMoment.clone().subtract(1, 'month');
    const nextMoment = currentMoment.clone().add(1, 'month');
    let firstWeekday = moment({ ...Now, days: 1 }).day();
    if (firstWeekday === 0) firstWeekday = 7; // fix moment.js weekdays
    const lastWeekday = moment({
      ...Now,
      days: currentMoment.daysInMonth(),
    }).day();

    // Get day method
    const getDay = (moment, dayInt) => {
      const Moment = moment.date(dayInt);
      const month = Settings.months.find((el) => el.id === Moment.month());
      const dayItem = {
        day: dayInt,
        weekdayInt: Moment.weekday(),
        weekdayRu: Moment.format('dddd'),
        monthInt: Moment.month(),
        monthRu: month ? month.titleRu : '',
        full: Moment.format('YYYY-MM-DD'),
        currentMonth: moment === currentMoment ? true : false,
      };
      return dayItem;
    };

    // Push prev month days
    if (firstWeekday !== 1) {
      let daysToPush = firstWeekday - 1;
      for (let index = prevMoment.daysInMonth(); index > 0; index--) {
        days.unshift(getDay(prevMoment, index));
        daysToPush--;
        if (daysToPush === 0) break;
      }
    }

    // Push current month days
    for (let index = 1; index <= currentMoment.daysInMonth(); index++) {
      days.push(getDay(currentMoment, index));
    }

    // Push next month days
    if (lastWeekday !== 0) {
      for (let index = 1; index <= 7 - lastWeekday; index++) {
        days.push(getDay(nextMoment, index));
      }
    }

    setGrid(days);

    return () => {
      days = [];
    };
  }, [Now]);

  // Request AlphaCRM common data
  useEffect(() => {
    setLoading(true);
    setErrorMessage('');

    // Get Events
    async function getEvents(token) {
      const now = moment(Now);
      const category = Settings.categoryCrm.find((el) => el.slug === Category);
      const data = {
        date_from: now.format('01.MM.YYYY'),
        date_to: now.format(now.daysInMonth() + '.MM.YYYY'),
        subject_id: category ? category.id : 1,
      };

      const items = await requestCrmData(token, 'lesson', data);
      return items;
    }

    // TODO: remove?
    // Get WP terms // Not used
    // async function getWpTerms() {
    //   return axios
    //     .get(Settings.requestUrl + '/wp-json/wp/v2/categories')
    //     .then((response) => {
    //       setWpCategory(response.data);
    //       return true;
    //     })
    //     .catch((error) => {
    //       throw new Error(error);
    //     });
    // }

    async function getCrmData() {
      // Get token
      const token = await Functions.getCrmToken();

      // Get & Set data
      const newAllCrmEvents = await getEvents(token);
      setAllCrmEvents(newAllCrmEvents);

      // Get subjects
      const newCrmSubjects = await requestCrmData(token, 'subject');
      setCrmSubjects(newCrmSubjects);

      // Get groups
      const newCrmGroups = await requestCrmData(token, 'group');
      setCrmGroups(newCrmGroups);

      setLoading(false);

      // Get rooms
      const newCrmRooms = await requestCrmData(token, 'room');
      setCrmRooms(newCrmRooms);

      // await getWpTerms(); // Not used
    }

    getCrmData().catch((error) => {
      setLoading(false);
      if (typeof error === 'object') {
        setErrorMessage(error.message);
      }
    });
    return () => {
      setAllCrmEvents([]);
    };
  }, [Now, Category]);

  // Reduce AlphaCRM events (run many times, that sad)
  useEffect(() => {
    if (AllCrmEvents.length && CrmGroups.length) {
      const ghosts = {};
      const events = AllCrmEvents.reduce((sum, item) => {
        if (!sum[item.date]) sum[item.date] = [];
        // Category
        const categoryCrm = Settings.categoryCrm.find(
          (el) => el.id === item.subject_id
        );
        const { title, term } = categoryCrm || {};
        const category = categoryCrm?.slug;
        // Group & members
        let group = CrmGroups.find((el) => el.id === item.group_ids[0]);
        // Subject
        const subject = CrmSubjects.find((el) => el.id === item.subject_id);
        // Room
        const room = CrmRooms.find((el) => el.id === item.room_id);
        // Check CRM dates format (move to settings?)
        const lessonDateCrmFormat = 'YYYY-MM-DD';
        const lessonDateTimeCrmFormat = 'YYYY-MM-DD hh:mm:ss';
        const groupDateCrmFormat = 'DD.MM.YYYY';
        // Set event end time
        const date = {
          key: item.date,
          start: moment(item.time_from, lessonDateTimeCrmFormat).format(),
          end: moment(item.time_to, lessonDateTimeCrmFormat).format(),
          duration: 1,
        };
        if (Functions.isLongEvent(category) && group) {
          date.end = moment(group.e_date, groupDateCrmFormat).format();
          date.duration += moment.range(date.start, date.end).diff('days') + 1;
        }
        // Set order
        let order = 0;
        if (date.duration > 1) order -= 1;
        // Set item
        let newItem = {
          id: item.id,
          title,
          note: item.note,
          description: item.topic,
          date,
          category,
          term,
          group,
          subject,
          location: room ? room.note : '',
          order,
        };
        // Push items
        const { key, duration } = newItem.date;
        // Add single item
        sum[key].push(newItem);
        // Add long extra items
        if (duration > 1) {
          for (let index = 1; index < duration; index++) {
            const futureKey = moment(key, lessonDateCrmFormat)
              .add(index, 'day')
              .format(lessonDateCrmFormat);
            const futureItem = {
              id: newItem.id,
              date: newItem.date,
              category: newItem.category,
              order: newItem.order - 1,
              isSecondDay: index === 1,
              isLastDay: index === duration - 1,
            };
            if (!ghosts[futureKey]) ghosts[futureKey] = [];
            ghosts[futureKey].push(futureItem);
          }
        }
        return sum;
      }, {});
      setCrmEvents(events);
      setCrmGhosts(ghosts);
    }

    return () => {
      setCrmEvents(null);
    };
  }, [AllCrmEvents, CrmGroups, CrmRooms, CrmSubjects]);

  return (
    <div className={`wrapper--content calendar ${Loading ? 'loading' : ''}`}>
      {Loading && <Loader />}
      <Nav setCategory={setCategory} now={Now} setNow={setNow} />
      <div className="calendar--header">
        {Settings.weekdays.map((item) => (
          <div key={item} className="calendar--weekday">
            {item}
          </div>
        ))}
      </div>
      <div className="calendar--body">
        {Grid &&
          Grid.map((item) => (
            <CalendarDay
              key={item.full}
              item={item}
              crmEvents={CrmEvents && CrmEvents[item.full]}
              crmGhosts={CrmGhosts && CrmGhosts[item.full]}
              setModalContent={setModalContent}
            />
          ))}
        {ErrorMessage && (
          <Warning
            errorMessage={ErrorMessage}
            setErrorMessage={setErrorMessage}
          />
        )}
      </div>
      {ModalContent && (
        <CalendarModal
          modalContent={ModalContent}
          setModalContent={setModalContent}
        />
      )}
    </div>
  );
};

export default Calendar;
